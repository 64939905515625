export const COLORS = {
  primary: "teal",
  danger: "red",
}

export const STATUS = {
  draft: "DRAFT",
  scheduled: "SCHEDULED",
  posted: "POSTED",
}
